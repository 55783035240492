import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
import SplitText from '../lib/SplitText.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);

import Swiper from 'swiper/bundle';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
	}
};

var winH = $(window).height();
var winW = $(window).width();
var body = $('body');

let tar_offset = document.querySelector('#header_nav').offsetHeight;


function anchorLink(){
	$('a.anchor').on('click', function(e){
		if( !$(this).hasClass('nopj') ){
			e.preventDefault();
			// e.stopPropagation();
			nav_hide();
			let hrefPageUrl = $(this).prop("href");
			hrefPageUrl = hrefPageUrl.split("#")[0];
			let currentUrl = location.href;
			currentUrl = currentUrl.split("#")[0];
			if( hrefPageUrl == currentUrl ){
				let href = this.hash;
				gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2'});	
			}
		}
	});
}


function elFunc(){
	let mm = gsap.matchMedia();
	document.querySelectorAll('.el').forEach((_tar, i) => {
		let _elmc = _tar.querySelectorAll('.elc');
		if( _elmc.length == 0 ){
			_elmc = _tar;
		}
		gsap.fromTo(_elmc,
			{ opacity:0 },
			{ opacity:1, 
				duration: 1.2,
				delay: 0.4,
				ease: "power4.out",
 				stagger: 0.1,
				scrollTrigger: {
					trigger: _tar,
					start: "top bottom",
					onEnter: function(e){
						e.trigger.classList.add('active');
					},		
					onRefresh: self => self.progress === 1 && self.animation.progress(1)
				}
			}
		);
	});

	const up_scrollTrigger = ScrollTrigger.create({
		trigger: body,
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > 10){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
					}
					self.prevSclrl = _slfscrl;
				}
			}
			scrltop (_slfscrl);
		},
	});
}
const scrltop = (_slfscrl) => {
	if(_slfscrl > 10){
		body.removeClass('scrltop');
		body.addClass('scrl');
	}else{
		body.removeClass('scrl');
		body.addClass('scrltop');
	}
}
scrltop(0);


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


let navimg = 0;
function nav_show(){
	const nav_img = document.querySelectorAll('#nav_img img');
	gsap.to(nav_img, {opacity:0, duration:0.1});
	gsap.to(nav_img[navimg], {opacity:1, duration:0.1});
	if(navimg == 9){
		navimg = 0;
	}else{
		navimg++;
	}

	body.addClass('navopen');
	const _tar = document.querySelectorAll('.nav_set_blc');
	_tar.forEach((_target) => {
		const _el = _target.querySelectorAll('.mv');
		gsap.fromTo(_el,{y:60,opacity:0},{
			y:0,
			opacity:1,
			duration: 0.6,
			ease: "power4.out",
			delay: 0.2,
			stagger: 0.05,
		});
	});
	nav_petal_show();
}
function nav_hide(){
	body.removeClass('navopen');
	nav_petal_hide();
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
	navChange();
	nav_petal();
}

let namespace = $('[data-barba="container"]').attr('data-barba-namespace');
function navChange(){
	nav_hide();
	namespace = $('[data-barba="container"]').attr('data-barba-namespace');
	const nav_li = document.querySelectorAll('#main_nav_blc .nav1 li');
	nav_li.forEach((item,i) => {
		if(item.classList.contains(namespace)){
			item.classList.add('current');
			current_petal(item, i, nav_li.length);
		}else{
			item.classList.remove('current');
		}
	});
}

let cur_pos,cur_rotate;
function current_petal(item, i, nav_li_length){
	const nav_petal = document.querySelector('#nav_petal');
	cur_pos = item.offsetTop + item.offsetHeight/2 - nav_petal.clientHeight/2;
	cur_rotate = 360 / nav_li_length * i;
	gsap.to(nav_petal, { y:cur_pos, rotation:cur_rotate });
}

function nav_petal(){
	const nav_parent = document.querySelector('#main_nav_blc .nav1');
	const nav_li = document.querySelectorAll('#main_nav_blc .nav1 li');
	const nav_petal = document.querySelector('#nav_petal');
	nav_li.forEach((item, i) => {
		const pos = item.offsetTop + item.offsetHeight/2 - nav_petal.clientHeight/2;
		const rotate = 360 / nav_li.length * i;
		item.addEventListener('mouseenter', () => {
			gsap.to(nav_petal, { y:pos, rotation:rotate, opacity:1, duration:0.1 });
		});
		item.addEventListener('click', () => {
			current_petal(item, i, nav_li.length);
		});
	});
	nav_parent.addEventListener('mouseleave', () => {
		if(nav_parent.querySelector('.current')){
			gsap.to(nav_petal, { y:cur_pos, rotation:cur_rotate, opacity:1, duration:0.1 });
		}else{
			gsap.to(nav_petal, { opacity:0, duration:0.1 });
		}
	});
}
function nav_petal_show(){
	const nav_parent = document.querySelector('#main_nav_blc .nav1');
	const nav_petal = document.querySelector('#nav_petal');
	if(nav_parent.querySelector('.current')){
		gsap.to(nav_petal, { opacity:1, duration:0.1, delay:0.6});
	}
}
function nav_petal_hide(){
	const nav_petal = document.querySelector('#nav_petal');
	gsap.to(nav_petal, { opacity:0 });
}



function imgParallax(){
	gsap.utils.toArray('.paraimg').forEach(tar => {
		const _inner = tar.firstElementChild;
		const para_x = () => {
			const _tarW = tar.clientWidth;
			const _innerW = _inner.clientWidth;
			const _x = _innerW - _tarW;
			return _x*-1;
		}
		const para_y = () => {
			const _tarH = tar.clientHeight;
			const _innerH = _inner.clientHeight
			const _y = _innerH - _tarH - 1;
			return _y*-1;
		}
		if( tar.classList.contains('paraimg_side') ){
			gsap.fromTo(_inner, {x: 0},
				{
					x: () => para_x(),
					ease: 'power1.out',
					scrollTrigger: {
						trigger: tar,
						start: 'top bottom',
						end: 'bottom center',
						scrub: 1,
						invalidateOnRefresh: true,
					},
				}
			);	
		}else{
			gsap.fromTo(_inner, {y: 0},
				{
					y: () => para_y(),
					ease: 'power1.out',
					scrollTrigger: {
						trigger: tar,
						start: 'top bottom',
						end: 'bottom center',
						scrub: 1,
						invalidateOnRefresh: true,
					},
				}
			);	
		}
	});
}


function splitText (){
	gsap.utils.toArray('.split').forEach(split_txt => {
		const textSplit = new SplitText(split_txt, {
			type: 'words,chars',
			charsClass: 'char',
		});
		const chars = textSplit.chars;
		chars.forEach((char) => {
			char.innerHTML = `<span>${char.textContent}</span>`;
		});
		const target = split_txt.querySelectorAll('.char span');
		const tl = gsap.timeline({paused: true})
		.fromTo(target,
			{y: '100%', opacity: 0},
			{y: '0%',
			opacity: 1,
			duration: 1.2,
			delay: 0.6,
			ease: 'power4',
			stagger: 0.02
		});

		const scrollTrigger = ScrollTrigger.create({
			trigger: split_txt,
			start: "top bottom",
			end: 'bottom top',
			onEnter: function(e){
				tl.play();
			},
		});
	});
}


function borderFunc(){
	document.querySelectorAll('.bd').forEach((el, i) => {
		const scrollTrigger = ScrollTrigger.create({
			trigger: el,
			start: "top bottom-=10%",
			end: 'bottom top',
			onEnter: function(e){
				e.trigger.classList.add('active');
			},
		});
	});
}


function top_Roomswiper() {
	const room_tab = document.querySelectorAll('.room_tab');
	const roomswiper = new Swiper('#topRoomSwiper', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true
		},
		speed: 800,
		spaceBetween: 10,
		on: {
			slideChange: function (e) {
				$(room_tab).removeClass('on');
				room_tab.forEach((item, i) => {
					const num = $(item).attr('data-num');
					const activeIndex = e.activeIndex;
					if(num == activeIndex){
						item.classList.add('on');
					}
				});
			},
		}
	});
	room_tab.forEach((item, i) => {
		item.addEventListener('click', () => {
			$(room_tab).removeClass('on');
			$(item).addClass('on');
			const num = $(item).attr('data-num');
			roomswiper.slideTo(num);
		});
	});
}


function top_Stayswiper() {
	const stayswiper = new Swiper('#topStaySwiper', {
		speed: 600,
		spaceBetween: 0,
		slidesPerView: 'auto',
		navigation: {
			nextEl: '#stay_next',
			prevEl: '#stay_prev',
		},
		keyboard: {
			enabled: true,
		},
		mousewheel: {
			enabled: true,
			forceToAxis: true,
		},
		scrollbar: {
			el: '.swiper-scrollbar',
			draggable: true,
			dragSize: 100,
		},
		breakpoints: {
			768: {
				scrollbar: {
					dragSize: 'auto',
				},
			},
		}	
	});
}


function boxParallax(){
	gsap.utils.toArray('.para').forEach(tar => {
		const _trigger = tar.parentElement;
		const _y = tar.dataset.para;
		gsap.fromTo(tar, {yPercent: _y},
			{
				yPercent: _y*-1,
				ease: 'power1.out',
				scrollTrigger: {
					trigger: _trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 2,
				},
			}
		);
	});
}


function room_swiper() {
	gsap.utils.toArray('.roomswiper').forEach(tar => {
		const roomswiper = new Swiper(tar, {
			speed: 600,
			spaceBetween: 0,
			slidesPerView: 1,
			navigation: {
				nextEl: '.next_arw',
				prevEl: '.prev_arw',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		});
	});
}


function post_swiper() {
	const swiper = new Swiper('#postSwiper', {
		slidesPerView: 1.1,
		speed: 600,
		spaceBetween: 20,
		loop: true,
		breakpoints: {
			768: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
		}	
	});
}


function expandFunc() {
	const expand = (parent, state) => {
		if(state){
			parent.classList.add('open');
		}else{
			parent.classList.remove('open');
		}
		const exc = parent.querySelector('.exc');
		gsap.utils.toArray(exc).forEach(ex => {
			gsap.to(ex,{
				height: (!state) ? 0 : "auto",
				duration:0.3,
				ease: "power2.inOut",
				onComplete: () => {
					ScrollTrigger.refresh();
				}
			});
		});
	}
	const expand_event = (e) => {
		let parent = e.target.closest('.ex');
		const result = parent.classList.contains('open');
		if (result) {
			expand(parent, false);
		}else{
			expand(parent, true);
		}
	};
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click',expand_event);
		exbtn.addEventListener('click',expand_event);
	});
}


function loading() {
	let loading_state = false;
	if(!document.getElementById('top')){
		document.getElementById('loading').style.display = 'none';
		loading_state = true;
		return false;
	}

	if(!loading_state){
		gsap.timeline()
		.to('#load_logo_red', {opacity:1, duration:0.8, delay:0.6})
		.to('#load_logo_wh', {scale:1, transformOrigin:'0% 100%', duration:0.8}, '<')

		.set('#load_red_bg', {y:'-5vw'})
		.set('#loading_bg', {opacity:0})
		.to('#load_logo_wh', {opacity:0, duration:0.8, ease: "power4.inOut"})

		.to('#load_logo_red', {y:'-110%', duration:2, ease: "power4.inOut"})
		.to('#load_logo_bk', {y:'110%', duration:2, ease: "power4.inOut"}, '<')
		.set('#loading', {autoAlpha:'0'})
		loading_state = true;
	}
}


function notFound_bg() {
	gsap.set("#bgpetal",{perspective:600})

	let total = 20;
	let container = document.getElementById("bgpetal");
	let w = window.innerWidth;
	let h = window.innerHeight;
	
	const animm = (elm) =>{
		gsap.to(elm,{filter: "blur("+R(0,8)+"px)",});
		gsap.to(elm,R(6,15),{y:h+100,ease:Linear.easeNone,repeat:-1,delay:-15});
		gsap.to(elm,R(4,8),{x:'+=100',rotationZ:R(0,180),repeat:-1,yoyo:true,ease:Sine.easeInOut});
		gsap.to(elm,R(2,8),{rotationX:R(0,360),rotationY:R(0,360),repeat:-1,yoyo:true,ease:Sine.easeInOut,delay:-5});
	};

	const R = (min,max) => {
		return min+Math.random()*(max-min)
	};

	const petal_bg = () => {
		for (let i=0; i<total; i++){ 
			var _div = document.createElement('div');
			gsap.set(_div,{attr:{class:'dot'},x:R(0,w),y:R(-200,-150),z:R(-400,400)});
			container.appendChild(_div);
			animm(_div);
		}
	};

	petal_bg();
	window.addEventListener('resize', () => {
		bgpetal.innerHTML = '';
		w = window.innerWidth;
		h = window.innerHeight;
		petal_bg();
	});
}


function notFound_kamon() {
	gsap.to('#kamon_svg', {rotate:360, duration: 2, ease: "power2.inOut"});

	const tl_petal_fill = gsap.timeline({repeat:-1, repeatDelay:0.6})
	.fromTo('.cls_petal', {fill:'#3E3A39'}, {fill:'#E8380D',duration:0.2,stagger:0.6})
	.to('.cls_petal', {fill:'#3E3A39',duration:0.2,stagger:0.6}, '<=+0.6');

	const tl = gsap.timeline()
	.to('#cir', {opacity:1, duration: 0.6, ease: "power2.inOut"})
	.to('.cls_petal', {opacity:1, duration: 0.6, ease: "power2.inOut", stagger: 0.1})
	.to('.frame path', {opacity:1, duration: 0.6, ease: "power2.inOut", stagger: 0.1}, '<0.6')
	.add(tl_petal_fill);
}

const contact_Cf7 = () => {
	if (wpcf7 && typeof wpcf7.init === 'function') { 
		document.querySelectorAll( '.wpcf7 > form' ).forEach( function( form ) {
			wpcf7.init( form );
		});
	}
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		loading();
	},
	commonFunc: function(){
		winH = $(window).height();
		winW = $(window).width();	
		anchorLink();
		elFunc();
		navChange();
		nav_petal();
		imgParallax();
		boxParallax();
		splitText();
		borderFunc();
		expandFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		body.addClass('navwh');
		top_Roomswiper();
		top_Stayswiper();
	},
	roomFunc: function(){
		room_swiper();
	},
	stayFunc: function(){
		body.addClass('navwh');
	},
	singleFunc: function(){
		post_swiper();
	},
	notfoundFunc: function(){
		notFound_bg();
		notFound_kamon();
	},
};

const replaceBogo = function(data){
	let target = '#wrapper';
	if(data){
		target = data.next.html;
	}
	const $newPageBogo = $(target).find('.bogo-language-switcher').html();
	body.find('#bogo').html($newPageBogo);
	$('#bogo').find('a').addClass('nopj');
}

function pjaxSettings(){

	barba.use(barbaPrefetch);

	// replaceBogo();

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
	links.forEach(function (link) {
		link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if (href == null) {
				href = el.getAttribute('xlink:href');
		}
		if (el.classList.contains('nopj')) {
				return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (!href.startsWith(site_url)) {
				el.setAttribute('target', '_blank');
				return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/, "");
		if (href.startsWith(location.protocol + '//' + location.host)) {
				if (href.indexOf('#') > -1 && extract_hash == url) {
						return false;
				}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
				el.setAttribute('target', '_blank');
				return true;
		}
		return false;
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.removeClass('scrltop');
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					body.addClass('scrltop');
					resolve();
				}});
			}
		})
	}


	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrl scrldown scrltop navwh lang_en');
	});

	barba.hooks.beforeEnter((data) => {
		replaceHead(data);
		// replaceBogo(data);
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
		scroll(data);
		async function callerFun(){
			pageCheck();
			if( document.getElementById('form_blc') ){
				contact_Cf7();
			}	
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

async function pageCheck() {
	await new Promise((resolve, reject) => {
		const barbaContainer = document.querySelector('.barba_container');
		let barbaNamespace;
		if (barbaContainer && barbaContainer.hasAttribute('data-barba-namespace')) {
			barbaNamespace = barbaContainer.getAttribute('data-barba-namespace');
		}
		PageEvents.commonFunc();
	
		if( barbaNamespace == 'home' ){
			PageEvents.topFunc();
		}else{
			body.addClass('inpage');
		}
		if( barbaNamespace == 'room' ){
			PageEvents.roomFunc();
		}
		if( barbaNamespace == 'stay' ){
			PageEvents.stayFunc();
		}
		if( barbaNamespace == 'single' ){
			PageEvents.singleFunc();
		}
		if( barbaNamespace == 'notfound' ){
			PageEvents.notfoundFunc();
		}

		if( document.getElementById('english') ){
			$('a').addClass('nopj');
		}

		body.addClass('ready');

		resolve();
	})
}


function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}